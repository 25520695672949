<template>
	<div>
		<div class="simple-title">Sign Up Via Square<span class="fal fa-store"></span></div>
		<div class="form-wrapper small">
			<div class="tabs"><router-link to="/signup/customer">Customer<span class="fal fa-user"></span></router-link><router-link to="/signup/business">Business<span class="fal fa-store"></span></router-link></div>
			<div class="form-container">
				<a href @click.prevent="() => loginDriver('business', 'square')" class="square-button"><img src="/images/third-party-logos/square.png" alt="">Sign Up Via Square</a>
				<div class="oauth-buffer"></div>
				<div class="text">
					<p>Getting on board with Fine Grounds is a one-click process. Just click the button above and follow the prompts to allow access to your menu items list from your square account, and you’re all set! Your shop will now be live for anybody who needs coffee in your area, and they’ll order and pay you exactly as they would if they were in your shop.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import SocialAuthMixin from '@/mixins/SocialAuthMixin';

export default {

	name: 'BusinessSignupPage',

	mixins: [ SocialAuthMixin ],

	data() {
		return {
			form: {},
		};
	},

	computed: mapGetters(['hasIntended', 'intendedRoute']),

};

</script>
